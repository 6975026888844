import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useTokenStore from "../store/store";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { loginUser, ResultLoginProps } from "../utils/user";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import Footer from "../components/headerFooter/Footer";

type Inputs = {
  email: string;
  password: string;
};

function Userlogin() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<ResultLoginProps>({
    message: "",
    status: 0,
    token: "",
    user: {
      id: "",
      fullname: "",
      email: "",
      userHandle: "",
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get('redirectTo');
  const setToken = useTokenStore((state) => state.setToken);
  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  // Inside Userlogin component
  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
  setLoading(true);
  const response = await loginUser(data);

  setMessage(response);
  setLoading(false);

  if (response.status === 200) {
    setToken(response);

    // Check for intendedPropertyId in sessionStorage
    const intendedPropertyId = sessionStorage.getItem('intendedPropertyId');
    if (intendedPropertyId) {
      sessionStorage.removeItem('intendedPropertyId');
      navigate(`/listing/${intendedPropertyId}`, { replace: true });
    }
    // Use redirectTo parameter if available
    else if (redirectTo && redirectTo.startsWith('/')) {
      navigate(redirectTo, { replace: true });
    } else {
      navigate("/listing", { replace: true });
    }
  }
};
  


  useEffect(() => {
    // Apply custom styles on the body to make sure it adjusts to the screen size
    const setBodyStyles = () => {
      document.body.style.display = "flex";
      document.body.style.flexDirection = "column";
      document.body.style.minHeight = "100vh";
      document.body.style.margin = "0";
      document.body.style.position = "relative";
      document.body.style.overflow = "auto";
    };

    setBodyStyles();

    return () => {
      document.body.style.display = "";
      document.body.style.flexDirection = "";
      document.body.style.minHeight = "";
      document.body.style.margin = "";
      document.body.style.position = "";
      document.body.style.overflow = "";
    };
  }, []);

  // White
  const isWhiteOrShade = (color: string) => {
    // Check if the color is exactly white
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
  
    // Check if the color is a shade of white (hex values like #fff or #ffffff)
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  
    // Check if the color is a shade of white (rgb or rgba format)
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      // If R, G, and B are close to 255, consider it a shade of white
      return r >= 240 && g >= 240 && b >= 240;
    }
  
    return false;
  };

  return (
    <div
      className="userlogin"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
        overflow: "auto",
      }}
    >
      <Helmet>
        <title>Login | {getThemes && getThemes.name}</title>
      </Helmet>

      {/* Background Pattern */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundPattern})`,
          backgroundRepeat: "repeat",
          backgroundSize: "60px 60px",
          zIndex: -1,
        }}
      />

      {/* Login Form */}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px",
          paddingTop: "160px",
          paddingBottom: "80px",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white flex flex-wrap justify-center pt-8 pb-8 px-4"
          style={{
            width: "85%",
            maxWidth: "500px",
            border: "1px solid #ddd",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
            backgroundColor: "white",
            marginBottom: "20px",
          }}
        >
          <div className="w-full">
            <p className="text-3xl font-roboto font-medium pb-8 pt-8 text-center">Login</p>

            {/* Email Input */}
            <div className="w-full py-2">
              <label
                htmlFor="email-input"
                className="block text-base font-medium mb-2 text-left"
                style={{
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Email Address
              </label>
              <input
                type="email"
                id="email-input"
                className="py-3 px-4 block w-full rounded-md text-sm"
                style={{
                  border: "1px solid black",
                  borderRadius: "0.375rem",
                  padding: "0.75rem",
                  color: "#2D3748",
                  backgroundColor: "#FFF",
                }}
                placeholder="Email Address"
                {...register("email", { required: true })}
              />
              <small className="flex justify-start pt-3 text-red-hover-ce312b">
                {errors.email && <span>Email Address is required</span>}
              </small>
            </div>

            {/* Password Input */}
            <div className="w-full py-2">
              <label
                htmlFor="password-input"
                className="block text-base font-medium mb-2 text-left"
                style={{
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Password
              </label>
              <input
                type="password"
                id="password-input"
                className="py-3 px-4 block w-full rounded-md text-sm"
                style={{
                  border: "1px solid black",
                  borderRadius: "0.375rem",
                  padding: "0.75rem",
                  color: "#2D3748",
                  backgroundColor: "#FFF",
                }}
                placeholder="Password"
                {...register("password", { required: true })}
              />
              <small className="flex justify-start pt-3 text-red-hover-ce312b">
                {errors.password && <span>Password is required</span>}
              </small>
            </div>

            {/* Login Button */}
            <div className="text-center">
            <button
              type="submit"
              style={{
                width: "30%",
                background: isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color2,
                color: isWhiteOrShade(getThemes.color1) ? "white" : getThemes.txtColor, // Text color logic
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                transition: "background 0.3s ease, color 0.3s ease",
                boxSizing: "border-box",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.background = isWhiteOrShade(getThemes?.color1) ? "black" : getThemes.color1; // Use black if color2 is white or near-white
                e.currentTarget.style.color = isWhiteOrShade(getThemes?.color1) ? "white" : getThemes.txtColor; // Adjust text color on hover
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.background = isWhiteOrShade(getThemes?.color2) ? "black" : getThemes.color2;
                e.currentTarget.style.color = isWhiteOrShade(getThemes.color1) ? "white" : getThemes.txtColor; // Adjust text color on mouse out
              }}
            >
              Log In
              {loading && (
                <span
                  className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                  role="status"
                  aria-label="loading"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </button>
              <Link
                to="/resetPassword"
                className="w-full inline-flex justify-center gap-2 font-medium tracking-wide hover:underline py-2"
              >
                Lost your password?
              </Link>
              <Link to="/register">
                <button
                  type="button"
                  className="w-full inline-flex justify-center gap-2 font-medium tracking-wide hover:underline py-2 pb-4"
                >
                  Do not have an account? Sign Up Here
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <Footer />

      {/* Error Message Overlay */}
      {message.status !== 0 && message.status !== 200 && (
        <div
          className="fixed inset-0 flex items-center justify-center"
          style={{ zIndex: 1000 }}
        >
          <div
            className="bg-white p-6 rounded shadow-md text-center"
            style={{
              width: "90%",
              maxWidth: "400px",
              backgroundColor: "white",
              zIndex: 1001,
            }}
          >
            <h2 className="text-xl font-bold mb-4">{message.message}</h2>
            <button
              onClick={() => setMessage({ ...message, status: 0 })}
              style={{
                width: "30%",
                background: getThemes.color1,
                color: getThemes.txtColor,
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                transition: "background 0.3s ease",
                boxSizing: "border-box",
              }}
              onMouseOver={(e) => (e.currentTarget.style.background = getThemes.color2)}
              onMouseOut={(e) => (e.currentTarget.style.background = getThemes.color1)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Userlogin;