import React, { useState, useEffect } from 'react';

interface PixelatedButtonProps {
  color1: string;
  color2: string;
  textColor: string;
  onClick: () => void;
}

const PixelatedButton: React.FC<PixelatedButtonProps> = ({ color1, color2, textColor, onClick }) => {
  const [pixels, setPixels] = useState<boolean[]>(Array(100).fill(false));
  const [isColor2, setIsColor2] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsColor2(prev => !prev);
      setPixels(Array(100).fill(false));
    }, 6000); // Change color every 4 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (pixels.some(p => !p)) {
      const timer = setTimeout(() => {
        setPixels(prev => {
          const newPixels = [...prev];
          const falseIndices = prev.reduce((acc, pixel, index) => pixel ? acc : [...acc, index], [] as number[]);
          const randomIndex = falseIndices[Math.floor(Math.random() * falseIndices.length)];
          if (randomIndex !== undefined) {
            newPixels[randomIndex] = true;
          }
          return newPixels;
        });
      }, 20); // Adjust this value to change the speed of the pixelation effect

      return () => clearTimeout(timer);
    }
  }, [pixels]);

  return (
    <button
      onClick={onClick}
      className="relative inline-flex items-center justify-center px-5 py-3 border border-transparent text-xl font-medium rounded-md text-white transition-all duration-500 ease-in-out overflow-hidden"
      style={{
        backgroundColor: isColor2 ? color2 : color1,
        color: textColor,
        minWidth: "200px",
        height: "50px",
      }}
    >
      <div className="absolute inset-0 grid grid-cols-10 grid-rows-10">
        {pixels.map((pixel, index) => (
          <div
            key={index}
            className="transition-colors duration-200"
            style={{
              backgroundColor: pixel ? (isColor2 ? color1 : color2) : 'transparent',
            }}
          />
        ))}
      </div>
      <span className="relative z-10">Get started</span>
    </button>
  );
};

export default PixelatedButton;