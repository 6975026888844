import React, { Fragment, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Header from "./components/headerFooter/Header";
import Footer from "./components/headerFooter/Footer";
import Userlogin from "./pages/Login";
import Userregister from "./pages/Register";
import Resetpwd from "./pages/Resetpwd";
import Property from "./pages/Property";
import PropertyListing from "./pages/PropertyListing";
import VerifyOtp from "./pages/VerifyOtp";
import VerifyOtpPassword from "./pages/VerifyOtpPassword";
import useThemeStore from "./store/themeStore";
import { getTheme, ThemeProps } from "./utils/theme";
import WhiteLabel from "./pages/WhiteLabel";
import Blog from "./pages/Blog";
import ScrollToTop from "./ScrollToTop";
import ServicesPage from "./pages/Services";
import PropertyValuation from "./pages/PropertyValuation";
import "./App.css"; // Your CSS file
import useTokenStore from "./store/store";

// New component to conditionally render Footer
const ConditionalFooter = () => {
  const location = useLocation();
  const noFooterRoutes = ['/login', '/register', '/resetPassword', '/verifyPasswordotp', '/verifyotp'];

  if (noFooterRoutes.includes(location.pathname)) {
    return null;
  }

  return <Footer />;
};

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const location = useLocation();
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  // Define themes that should allow viewing property details without login
  const themesWithoutLogin = ['My Property Listing', 'Test Site 1', 'Test Site 2'];
  const canViewWithoutLogin = themesWithoutLogin.includes(getThemes?.name);

  // Only redirect to login if a token is missing and the theme requires login
  if (!getToken && !canViewWithoutLogin) {
    const intendedUrl = location.pathname + location.search;
    return (
      <Navigate
        to={`/login?redirectTo=${encodeURIComponent(intendedUrl)}`}
        replace
      />
    );
  }
  
  return element;
};


function App() {
  const setTheme = useThemeStore((state) => state.setTheme);
  const [loading, setLoading] = useState(true);

  const fetchTheme = useCallback(async () => {
    const response = await getTheme();
    if (response) {
      setTheme(response);
      setLoading(false);
    }
  }, [setTheme]);

  useEffect(() => {
    fetchTheme();
  }, [fetchTheme]);

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  return (
    <div className="App">
      {!loading && (
        <Router basename={"/"}>
          <ScrollToTop>
            <Fragment>
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={
                    getThemes && getThemes.hybrid ? (
                      <PropertyListing />
                    ) : (
                      <WhiteLabel />
                    )
                  }
                />
                {/* Public Routes */}
                <Route path="/login" element={<Userlogin />} />
                <Route path="/register" element={<Userregister />} />
                <Route path="/resetPassword" element={<Resetpwd />} />
                <Route path="/verifyPasswordOtp" element={<VerifyOtpPassword />} />
                <Route path="/verifyotp" element={<VerifyOtp />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/propertyvaluation" element={<PropertyValuation />} />
                <Route path="/listing" element={<PropertyListing />} />
                
                {/* Private Routes */}
                <Route path="/listing/:id" element={<PrivateRoute element={<Property />} />} />
                <Route path="/listing/:id/failure" element={<PrivateRoute element={<Property />} />} />
                <Route path="/listing/:id/success" element={<PrivateRoute element={<Property />} />} />
              </Routes>
              <ConditionalFooter />
            </Fragment>
          </ScrollToTop>
        </Router>
      )}
      {loading && (
        <div className="flex justify-center items-center h-screen">Loading...</div>
      )}
    </div>
  );
}

export default App;