import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
  faBed,
  faShareAlt,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTokenStore from "../../store/store";
import { numberFormat } from "../../utils/properties";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface PropertyCardProps {
  id: string;
  discount: string;
  image: string;
  streetName: string;
  price: string;
  bedrooms: string;
  value: string;
  rent: string;
  netCashFlow: string;
  grossYield: string;
  strategy?: string;
  discountLabel?: string;
  available: boolean;
  tenureType?: string;
  occupancy?: string;
  floorsqft?: string;
  region?: string;
  investment?: string;
  isFeatured?: boolean;
  onShareClick: () => void;
  onClick: () => void;

}

const PropertyCard = ({
  id,
  discount,
  available,
  image,
  streetName,
  price,
  bedrooms,
  value,
  rent,
  grossYield,
  netCashFlow,
  strategy,
  occupancy,
  tenureType,
  floorsqft,
  region,
  investment,
  onShareClick,
  isFeatured = false,
  onClick,
}: PropertyCardProps) => {
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  

  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    // Check if the current theme is one of those that allows viewing without logging in
    const canViewWithoutLogin = themesWithoutZoopla.includes(getThemes?.name);
    
  
    // If the user is not logged in and the theme requires login, prevent navigation
    if (!getToken && !canViewWithoutLogin) {
      e.preventDefault();
      sessionStorage.setItem('intendedPropertyId', id); // Save the property ID
      navigate('/login'); // Navigate to the login page
    } else {
      // No need to call navigate here, as <Link> will handle the navigation
    }
  };

  const roundedYield = Math.ceil(Number(grossYield));
  const roundedInvestment = Math.round(Number(investment));

  // Format strategy display
  const displayStrategy = strategy === "Housing Association" ? "H.A" : (strategy === "Serviced Accommodation" ? "Serviced" : strategy);

  // Utility function to check for white or near-white shades
  const getBackgroundAndTextColor = (color: string, fallbackColor: string, defaultTextColor: string) => {
    if (isWhiteOrShade(color)) {
      return {
        backgroundColor: fallbackColor,
        textColor: 'white', // Set the text color to white if the background is near white
      };
    } else {
      return {
        backgroundColor: color,
        textColor: defaultTextColor, // Keep the original text color
      };
    }
  };
  
  const isWhiteOrShade = (color: string) => {
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      return r >= 240 && g >= 240 && b >= 240;
    }
  
    return false;
  };

  // Define themes that should display "Net Cashflow" instead of "Zoopla Valuation"
  const themesWithoutZoopla = ['My Property Listing', 'Test Site 1', 'Test Site 2'];

  // Determine the label based on the current theme
  const valuationLabel = themesWithoutZoopla.includes(getThemes?.name) 
    ? 'Net Monthly Cashflow' 
    : 'Zoopla Valuation';

  return (
    <Link to={`/listing/${id}`} onClick={handleClick}>
      <div className="cursor-pointer">
      <div className={`max-w-7xl mx-auto mb-5 ${isFeatured ? 'ring-2 ring-yellow-400' : ''}`}>
        <div className="border rounded-xl relative shadow-md hover:shadow-xl transition duration-150 ease-in-out">
          {/* Top Bar with Price and Yield */}
          <div className="flex justify-between items-center p-2 bg-gray-100 rounded-t-xl"
            style={{
              background: `${getThemes && getThemes.color1}`,
              color: `${getThemes && getThemes.txtColor}`,
            }}>
            <span className="font-bold bg-green-200 text-green-700 py-1 px-3 rounded-md">
              £{numberFormat(price as unknown as number)}
            </span>
            <span className="font-bold">
              {roundedYield}% {strategy === "Housing Association" ? "Net" : "Gross"} Yield
            </span>
          </div>

          {/* Property Image */}
          <div className="relative">
            <LazyLoadImage
              src={image}
              width={150}
              height={150}
              className="h-[250px] w-full object-cover"
              alt="Property Image"
            />
          </div>

          {/* Property Details */}
          <div className="p-4">
            <h3 className="font-roboto font-medium text-lg mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {streetName}
            </h3>
            <p className="text-gray-600 mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {region && region !== "false" ? region : <span>&nbsp;</span>}
            </p>
            
            <div className="flex flex-wrap gap-2 mb-2 overflow-hidden">
            {!available && (
              <span
                className="text-xs font-bold py-1 px-2 rounded-md"
                style={{
                  background: isWhiteOrShade(getThemes?.color1) ? 'black' : getThemes?.color2, // Use black if color1 is white or a near-white shade
                  color: isWhiteOrShade(getThemes?.color1) ? "white" : getThemes?.txtColor, // Keep text color as white if background is black
                }}>
                Reserved
              </span>
            )}
              {parseFloat(discount) > 0 && (
                <span 
                  className="text-xs font-bold text-white py-1 px-2 rounded-md"
                  style={{
                    background: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).backgroundColor,
                    color: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).textColor,
                  }}
                >
                  {discount}% Discount
                </span>
              )}
              {occupancy && occupancy.toLowerCase() === 'tenanted' && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                style={{
                  background: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).backgroundColor,
                  color: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).textColor,
                }}>
                  Tenanted
                </span>
              )}
              {tenureType && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                style={{
                  background: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).backgroundColor,
                  color: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).textColor,
                }}>
                  {tenureType}
                </span>
              )}
              {displayStrategy && (
                <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                style={{
                  background: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).backgroundColor,
                  color: getBackgroundAndTextColor(getThemes.color1, getThemes.color2, getThemes.txtColor).textColor,
                }}>
                  {displayStrategy}
                </span>
              )}
            </div>
            <ul className="mt-3">
            <li className="flex justify-between py-1">
              <span>{valuationLabel}</span>
              <span className="font-bold">
                £{numberFormat(
                  valuationLabel === 'Net Monthly Cashflow' 
                    ? Math.ceil(Number(netCashFlow)) // Rounds up the net cash flow value
                    : value as unknown as number
                )}
              </span>
            </li>
              <li className="flex justify-between py-1">
                <span>Total Investment</span>
                <span className="font-bold">£{numberFormat(roundedInvestment)}</span>
              </li>
              <li className="flex justify-between py-1">
                <span>Rental Income (pcm)</span>
                <span className="font-bold">£{numberFormat(rent as unknown as number)}</span>
              </li>
            </ul>

            <div className="flex justify-between items-center mt-3">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faBed} />
                <span>{bedrooms}</span>
              </div>
              {floorsqft && (
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faRulerCombined} />
                  <span>{floorsqft}</span>
                </div>
              )}
              <div className="flex items-center gap-2">
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onShareClick();
                  }} 
                  className="focus:outline-none"
                  style={{ 
                    transition: 'transform 0.2s', 
                    fontSize: '1.1rem' 
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Link>
  );
};

export default PropertyCard;
