import axios from "axios";
import { baseUrl } from ".";

export interface ThemeProps {
  adminLastName: any;
  adminFirstName: string;
  adminPhoneNumber: string;
  _id: any;
  color1: string;
  color2: string;
  txtColor: string;
  logo: string;
  token: string;
  website: string;
  subwebsite: string;
  name: string;
  favIcon: string;
  hybrid: Boolean;
  googleAnalytics: string;
  hideQR?: Boolean;
  hideDealSourcing?: Boolean;
  hideConveyancing?: Boolean;
  hideMortgageBroker?: Boolean;
  hidePropertyManagement?: Boolean;
  hidePropertyRefurbishment?: Boolean;
  hideSellProperty?: Boolean;
  hideStampDutyReclaim?: Boolean;
  hideStartCompany?: Boolean;

}

export const getTheme = async (): Promise<ThemeProps> => {
  try {
    const response = await axios.get(`${baseUrl}/whitelabel/theme`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });
    return response.data.results;
  } catch (error) {
    console.log(error);
    window.location.reload();
    return undefined as any;
  }
};
