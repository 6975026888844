import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import { CUSTOM_CARDS, ProductIds } from "../pages/components/FeaturedCardsCarousel";

const useCardList =()=>{
  const themes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  // Filtering logic based on theme settings
  const visibleCards = CUSTOM_CARDS.filter((card: { productId: any; }) => {
    if (card.productId === ProductIds.dealSourcing && themes?.hideDealSourcing) return false;
    if (card.productId === ProductIds.conveyancing && themes?.hideConveyancing) return false;
    if (card.productId === ProductIds.mortgageBroker && themes?.hideMortgageBroker) return false;
    if (card.productId === ProductIds.productManagement && themes?.hidePropertyManagement) return false;
    if (card.productId === ProductIds.propertyRefurbishment && themes?.hidePropertyRefurbishment) return false;
    if (card.productId === ProductIds.sellAProperty && themes?.hideSellProperty) return false;
    if (card.productId === ProductIds.stampDutyReclaim && themes?.hideStampDutyReclaim) return false;
    if (card.productId === ProductIds.startCompany && themes?.hideStartCompany) return false;
    return true;
  });


    return visibleCards

  
};

export default useCardList;
