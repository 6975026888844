import React, { useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import useThemeStore from "../store/themeStore";
import { ContactFormProps, contactFormContact } from "../utils/properties";
import { ThemeProps } from "../utils/theme";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import image1 from "../images/vesta/large-cityscape-removebg.png"; // Importing the image

const GenInTouch = () => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<'success' | 'error'>('success');
  const [showMessageModal, setShowMessageModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ContactFormProps>();

  const onSubmit: SubmitHandler<ContactFormProps> = async (data: ContactFormProps) => {
    setLoading(true);
    try {
      const response = await contactFormContact(data);
      // Reset form fields
      setFirstname("");
      setLastname("");
      setEmail("");
      setPhone("");
      setDescription("");
      // Set success message
      setMessage(response.message || "Thank you for getting in touch. A member of our team will be in touch shortly.");
      setMessageType('success');
      reset();
    } catch (error) {
      // Set error message
      setMessage("An error occurred while processing your request. Please try again.");
      setMessageType('error');
    } finally {
      setLoading(false);
      setShowMessageModal(true);
      // Automatically hide the modal after 5 seconds
      setTimeout(() => {
        setShowMessageModal(false);
        setMessage("");
      }, 5000);
    }
  };

  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  
  const themes = getThemes || {}; // Add default if themes are undefined

  const closeModal = () => {
    const backdrop = document.querySelector('.hs-overlay-backdrop');
    if (backdrop) {
      // @ts-ignore
      backdrop.click();
    }
  };

  // White
  const isWhiteOrShade = (color: string) => {
    // Check if the color is exactly white
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
  
    // Check if the color is a shade of white (hex values like #fff or #ffffff)
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  
    // Check if the color is a shade of white (rgb or rgba format)
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      // If R, G, and B are close to 255, consider it a shade of white
      return r >= 240 && g >= 240 && b >= 240;
    }
  
    return false;
  };
  
  return (
    <>
      <div
        id="hs-large-modal"
        className="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[70] overflow-x-hidden overflow-y-auto"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (e.target === e.currentTarget) {
            closeModal();
          }
        }}
      >
        <div className="hs-overlay-open:mt-20 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-7xl lg:w-full m-3 lg:mx-auto rounded-b-xl relative" style={{background: "white"}}>
          <div className="relative w-full z-10" style={{ 
              backgroundImage: `url(${backgroundPattern})`, 
              backgroundRepeat: 'repeat', 
              backgroundSize: '75px 75px' 
            }}>
            <div className="flex flex-col shadow-sm rounded-xl relative z-20">
              <div className="flex justify-between items-center py-3 px-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    closeModal();
                  }}
                  className="absolute"
                  style={{
                    top: "50px",
                    right: "4px",
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: "bold",
                    zIndex: 1000,
                    width: "25px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "transform 0.2s",
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                  &times;
                </button>
              </div>
              <div className="overflow-y-auto pb-5">
                <div className="flex flex-wrap lg:flex-nowrap lg:space-x-4 font-poppins text-left p-10 font-light">
                  <div className="lg:w-1/2 w-full lg:pr-2 pr-0 p-4 border-gray-300 border shadow-sm rounded-xl lg:ml-[-20px]" style={{background: "rgba(255, 255, 255, 0.89)"}}>
                    <h5 className="text-xl">
                      Find Your Next Buy-To-Let Property
                    </h5>
                    <h3 className="text-6xl py-4">Get in touch</h3>
                    <h4 className="text-base tracking-wide">
                      If you would like to request information about any of the
                      properties available on our site, or would like to
                      understand how you can easily secure your next investment
                      property, please contact us via this form.
                    </h4>
                  </div>
                  <div className="lg:w-1/2 w-full lg:mt-0 mt-5">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex flex-wrap"
                    >
                      <div className="w-1/2 pr-1 border-gray-200">
                        <input
                          value={firstname}
                          onInput={(e) => setFirstname(e.currentTarget.value)}
                          type="text"
                          required
                          className="py-3 mb-2 px-4 block w-full border-black border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-black"
                          placeholder="First Name*"
                          {...register("firstname", { required: true })}
                        />
                      </div>
                      <div className="w-1/2 pl-1">
                        <input
                          value={lastname}
                          onInput={(e) => setLastname(e.currentTarget.value)}
                          type="text"
                          required
                          className="py-3  mb-2 px-4 block w-full border-black border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-black"
                          placeholder="Last Name*"
                          {...register("lastname", { required: true })}
                        />
                      </div>
                      <div className="w-1/2 pr-1">
                        <input
                          onInput={(e) => setEmail(e.currentTarget.value)}
                          value={email}
                          type="email"
                          required
                          className="py-3 mb-2 px-4 block w-full border-black border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-black"
                          placeholder="Email*"
                          {...register("email", { required: true })}
                        />
                      </div>
                      <div className="w-1/2 pl-1">
                        <input
                          onInput={(e) => setPhone(e.currentTarget.value)}
                          value={phone}
                          type="tel"
                          required
                          className="py-3 mb-2 px-4 block w-full border-black border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-black"
                          placeholder="Phone*"
                          {...register("mobilephone", { required: true })}
                        />
                      </div>
                      <textarea
                        className="py-3 mb-3 px-4 block w-full border-black border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-900 text-black"
                        rows={3}
                        onInput={(e) => setDescription(e.currentTarget.value)}
                        value={description}
                        placeholder="Message"
                        required
                        {...register("message", { required: true })}
                      ></textarea>

                      <button
                        type="submit"
                        style={{
                          backgroundColor: themes.color2,
                          width: "30%",
                          color: isWhiteOrShade(themes.color1) ? "white" : themes.txtColor,
                          padding: "10px",
                          borderRadius: "5px",
                          border: "none",
                          cursor: "pointer",
                          transition: "background 0.3s ease, color 0.3s ease",
                          boxSizing: "border-box",
                          margin: "0 auto",
                          display: "block"
                        }}
                        className="transition duration-300 ease-in-out"
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = isWhiteOrShade(themes?.color1) ? "black" : themes?.color1 || "alternateHoverColor";
                          e.currentTarget.style.color = isWhiteOrShade(themes?.color1) ? "white" : themes.txtColor;
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = themes?.color2 || "defaultColor";
                          e.currentTarget.style.color = isWhiteOrShade(themes?.color1) ? "white" : themes.txtColor;
                        }}
                      >
                        Send
                        {loading && (
                          <span
                            className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full ml-2"
                            role="status"
                            aria-label="loading"
                          ></span>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <img src={image1} alt="Cityscape" className="absolute rounded-b-xl pt-10 bottom-0 left-0 w-129 h-68 z-1"/>
          </div>
        </div>
      </div>

      {/* Message Modal */}
      {showMessageModal && (
        <div
          className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full relative" style={{backgroundColor: "white"}}>
            <button
              onClick={() => setShowMessageModal(false)}
              className="absolute top-2 right-2 text-black-500 hover:text-black-700"
              aria-label="Close"
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              &times;
            </button>
            <div className="flex flex-col items-center">
              <p className={`text-center text-lg ${messageType === 'success' ? 'text-green-700' : 'text-red-700'}`}>
                {message}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GenInTouch;
