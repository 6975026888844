import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ReviewStars = () => {
  return (
    <>
      <div className="pb-2">
        <FontAwesomeIcon icon={faStar} className="text-yellow" />
        <FontAwesomeIcon icon={faStar} className="text-yellow" />
        <FontAwesomeIcon icon={faStar} className="text-yellow" />
        <FontAwesomeIcon icon={faStar} className="text-yellow" />
        <FontAwesomeIcon icon={faStar} className="text-yellow" />
      </div>
    </>
  );
};

export default ReviewStars;
