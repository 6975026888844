import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { PropertyExtraResult } from '../../utils/properties';
import miniCityscape1 from "../../images/vesta/Conveyancing.png";
import miniCityscape2 from "../../images/vesta/StampDuty.svg";
import miniCityscape3 from "../../images/vesta/large-cityscape-crop.png";
import miniCityscape4 from "../../images/vesta/mini-cityscape.svg";

interface ThemeProps {
  color1?: string;
  color2?: string;
  txtColor?: string;
}

interface ComparablesSectionProps {
  property: PropertyExtraResult | undefined;
  getThemes: ThemeProps;
}

interface ComparableCardProps {
  title: string;
  data: (string | null)[] | null | undefined;
  getThemes: ThemeProps;
  imageIndex: number;
}

function cleanString(input: string): string {
  return input.replace(/[^a-zA-Z0-9\s,-]/g, '');
}

const cityscapeImages = [miniCityscape1, miniCityscape2, miniCityscape3, miniCityscape4];

const ComparableCard: React.FC<ComparableCardProps> = ({ title, data, getThemes, imageIndex }) => {
  const formatAddress = (address: string) => {
    const parts = address.split(',');
    let firstLine, secondLine;
    
    if (parts.length <= 2) {
      firstLine = address;
    } else {
      firstLine = parts.slice(0, 2).join(',') + ',';
      secondLine = parts.slice(2).join(',').trim();
    }

    return (
      <div className="text-center">
        <div>{firstLine}</div>
        {secondLine && <div>{secondLine}</div>}
      </div>
    );
  };

  const hasValidData = data && data.length > 0 && data.some(item => item && item !== 'N/A');

  return (
    <div className="bg-white border-gray-300 border rounded-lg shadow-lg p-6 flex flex-col hover:shadow-xl transition duration-150 ease-in-out" style={{backgroundColor: "white", height: "450px" }}>
      <div className="border-b border-gray-200 pb-3 mb-4">
        <h3 className="text-lg font-semibold text-center">
          {title}
        </h3>
      </div>
      {hasValidData ? (
        <div className="flex-grow flex flex-col justify-between">
          {data!.slice(0, 3).map((ele, index) => {
            const [address, price] = (ele && cleanString(ele).split('-')) || [];
            if (address === 'N/A' && price === 'N/A') return null;
            return (
              <div key={index} className="flex-1 flex flex-col items-center justify-center relative">
                <div className="flex items-center justify-center mb-2">
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                </div>
                <div className="text-sm mb-2">
                  {formatAddress(address || 'N/A')}
                </div>
                <div className="text-sm text-center">
                  <span className="font-semibold">Price:</span> £{price ? price.trim() : 'N/A'}
                </div>
                {index < 2 && <div className="absolute bottom-0 w-1/2 h-px bg-gray-200"></div>}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex-grow flex flex-col items-center justify-between">
          <div className="text-center text-gray-500 mt-8">
            No Data Currently Available
          </div>
          <img
            src={cityscapeImages[imageIndex]}
            className="w-full h-auto mt-auto"
            alt={`Mini Cityscape ${imageIndex + 1}`}
          />
        </div>
      )}
    </div>
  );
};

const ComparablesSection: React.FC<ComparablesSectionProps> = ({ property, getThemes }) => {
  const comparableTypes = [
    { title: "Previously Sold - Within The Area", data: property?.results.marketComparable },
    { title: "Previously Sold - Same Street", data: property?.results.streetComparable },
    { title: "For Sale - Within The Area", data: property?.results.saleComparable },
    { title: "For Rent - Within The Area", data: property?.results.rentComparable }
  ];

  if (!property) return null;

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {comparableTypes.map((type, index) => (
          <ComparableCard 
            key={index} 
            title={type.title} 
            data={type.data} 
            getThemes={getThemes} 
            imageIndex={index % cityscapeImages.length}
          />
        ))}
      </div>
    </div>
  );
};

export default ComparablesSection;