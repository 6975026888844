import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook to determine if the current device is mobile based on the viewport width.
 * @param breakpoint - The width (in pixels) below which the device is considered mobile. Default is 768px.
 * @returns A boolean indicating whether the device is mobile.
 */
const useIsMobile = (breakpoint: number = 768): boolean => {
  const isClient = typeof window === 'object';

  const getIsMobile = useCallback(() => 
    isClient ? window.innerWidth < breakpoint : false
  , [isClient, breakpoint]);

  const [isMobile, setIsMobile] = useState<boolean>(getIsMobile());

  useEffect(() => {
    if (!isClient) {
      console.log('useIsMobile: Not running on the client side.');
      return;
    }

    const handleResize = () => {
      const currentIsMobile = getIsMobile();
      setIsMobile(currentIsMobile);
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();


    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, getIsMobile, isMobile]);

  // Logging on isMobile change
  useEffect(() => {
  }, [isMobile]);

  return isMobile;
};

export default useIsMobile;