import React, { useState } from 'react';
import { Helmet } from "react-helmet";


const PropertyValuationPage: React.FC = () => {
  const [isPropertyInfoModalOpen, setIsPropertyInfoModalOpen] = useState(false);
  const [isPersonalInfoModalOpen, setIsPersonalInfoModalOpen] = useState(false);


  const handleUnlockValuation = () => {
    setIsPropertyInfoModalOpen(true);
  };

  const handleValueProperty = () => {
    setIsPersonalInfoModalOpen(true);
  };

  return (
    <div className="PropertyValuationPage bg-white pt-28">
      <Helmet>
        <title>Property Valuation</title>
        <meta name="description" content="Get a free valuation for your property" />
      </Helmet>

      <div className="max-w-7xl pt-10 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
            Sell your property with 
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Sell your buy-to-let property and maximise returns
          </p>
        </div>

        <div className="bg-gray-50 rounded-lg shadow-md p-6 mb-12">
          <button
            onClick={handleUnlockValuation}
            className="bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Unlock free valuation
          </button>
          <span className="ml-4 text-gray-600">
            and see how much you could sell your property for.
          </span>
        </div>

        <div className="space-y-4 mb-12 font-center">
          <Feature text="Sell tenanted - investor-to-investor sales with no breaks in cashflow" />
          <Feature text="Exit your whole portfolio - a single point of contact to exit your whole portfolio" />
          <Feature text="Incredible distribution - exposure to 100K landlords worldwide, local agents and more" />
          <Feature text="Sell your company or property - flexibility to sell your shares or property, with access to exclusive share financing products" />
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-4 pb-20">
            Designed to sell your property fast, at the price you want
          </h2>
          <ol className="space-y-4">
            <Step number={1} title="Get a property valuation" description="See what you could achieve from your sale" />
            <Step number={2} title="Complete an order form" description="Confirm you agree to our fees and listing your property with us exclusively for 3 months" />
          </ol>
          <div className="mt-6 bg-blue-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-900">Cost of selling your property with</h3>
            <p className="text-3xl font-bold text-gray-900 mt-2">1.5% <span className="text-base font-normal">+ VAT</span></p>
            <p className="text-sm text-gray-600">of agreed property sale price</p>
            <p className="mt-2 text-sm text-gray-600">Minimum fee £1,999 + VAT per property</p>
          </div>
        </div>
      </div>

      {isPropertyInfoModalOpen && (
        <PropertyInfoModal onClose={() => setIsPropertyInfoModalOpen(false)} onSubmit={handleValueProperty} />
      )}

      {isPersonalInfoModalOpen && (
        <PersonalInfoModal onClose={() => setIsPersonalInfoModalOpen(false)} />
      )}
    </div>
  );
};

interface FeatureProps {
  text: string;
}

const Feature: React.FC<FeatureProps> = ({ text }) => (
  <div className="flex items-center">
    <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
    <span>{text}</span>
  </div>
);

interface StepProps {
  number: number;
  title: string;
  description: string;
}

const Step: React.FC<StepProps> = ({ number, title, description }) => (
  <li className="flex">
    <div className="flex-shrink-0">
      <div className="flex items-center justify-center h-8 w-8 rounded-full bg-blue-500 text-white">
        {number}
      </div>
    </div>
    <div className="ml-4">
      <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-600">{description}</p>
    </div>
  </li>
);

interface PropertyInfoModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const PropertyInfoModal: React.FC<PropertyInfoModalProps> = ({ onClose, onSubmit }) => {
  // Add form state and handling logic here
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full" style={{backgroundColor: "white"}}>
        <h2 className="text-2xl font-bold mb-4">Tell us about your property</h2>
        {/* Add form fields here */}
        <button onClick={onSubmit} className="bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4">
          Value property
        </button>
        <button onClick={onClose} className="ml-2 text-gray-600">
          Back
        </button>
      </div>
    </div>
  );
};

interface PersonalInfoModalProps {
  onClose: () => void;
}

const PersonalInfoModal: React.FC<PersonalInfoModalProps> = ({ onClose }) => {
  // Add form state and handling logic here
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">One more thing</h2>
        {/* Add form fields here */}
        <button onClick={onClose} className="bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4">
          Unlock valuation
        </button>
      </div>
    </div>
  );
};

export default PropertyValuationPage;