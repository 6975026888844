import React, { useState, useRef, useEffect } from 'react';
import searchIcon from '../../images/SearchIcon1.png'; // Adjust the path as needed

const SearchComponent: React.FC<{
  searchKeyword: string;
  handleSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getThemes: any;
}> = ({ searchKeyword, handleSearchInputChange, getThemes }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsSearchVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // White
  const isWhiteOrShade = (color: string) => {
    // Check if the color is exactly white
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
  
    // Check if the color is a shade of white (hex values like #fff or #ffffff)
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  
    // Check if the color is a shade of white (rgb or rgba format)
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      // If R, G, and B are close to 255, consider it a shade of white
      return r >= 240 && g >= 240 && b >= 240;
    }
  
    return false;
  };
  
  // Usage in JSX
  const borderColor = getThemes && isWhiteOrShade(getThemes.color1) ? getThemes.color2 : getThemes.color1;
  

  return (
    <div ref={searchRef} className="relative">
      <div
        onClick={toggleSearch}
        className="flex items-center cursor-pointer"
      >
        <img 
          src={searchIcon} 
          alt="Search" 
          className="w-10 h-10 mr-2" // Adjust size as needed
        />
        <span>Search Properties</span>
      </div>
      {isSearchVisible && (
        <div className="op-full -left-16 -mt-10 w-64">
          <input
            type="text"
            id="searchProperty"
            className="py-2 px-3 w-full border-primarycolor border-2 rounded-md text-sm"
            placeholder="Enter Street Name or Postcode"
            value={searchKeyword}
            onChange={handleSearchInputChange}
            style={{ borderColor }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchComponent;