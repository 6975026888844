import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ThemeProps } from "../utils/theme";

const themeStore = (set: any) => ({
  theme: undefined,
  setTheme: (theme: ThemeProps) => {
    set((state: any) => ({
      theme: theme,
    }));
  },
  removeToken: () => {
    set(() => ({
      theme: undefined,
    }));
  },
});

const useThemeStore = create(
  devtools(
    persist(themeStore, {
      name: "theme",
    })
  )
);

export default useThemeStore;
