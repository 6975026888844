import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faBars } from '@fortawesome/free-solid-svg-icons';

const GridIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="22" // Reduce the width of the SVG
    height="22" // Reduce the height of the SVG
    fill="currentColor"
    style={{ display: 'block', width: '100%', height: '100%' }} // Ensure the SVG fills its container
  >
    <rect x="3" y="3" width="8" height="8" rx="2" />
    <rect x="13" y="3" width="8" height="8" rx="2" />
    <rect x="3" y="13" width="8" height="8" rx="2" />
    <rect x="13" y="13" width="8" height="8" rx="2" />
  </svg>
);

interface ViewModeSelectorProps {
  viewMode: string;
  setViewMode: (viewMode: string) => void;
  getThemes: { color1: string, color2: string }; // Assuming getThemes contains these properties
}

const ViewModeSelector: React.FC<ViewModeSelectorProps> = ({ viewMode, setViewMode, getThemes }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isMobile) {
    return null;
  }

  const defaultBackgroundColor = 'rgba(229, 231, 235, 1)';
  const selectedBackgroundColor = getThemes && getThemes.color2;

  const buttonStyle = {
    padding: '8px',
    width: '36px', // Ensure all buttons have the same width
    height: '36px', // Ensure all buttons have the same height
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    background: defaultBackgroundColor,
    color: 'black',
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    background: selectedBackgroundColor,
    color: 'white',
  };

  return (
    <div style={{ display: 'flex', gap: '8px', paddingRight: '2px' }}>
      <button
        onClick={() => setViewMode('detail')}
        style={viewMode === 'detail' ? selectedButtonStyle : buttonStyle}
      >
        <FontAwesomeIcon icon={faBars} style={{ fontSize: '20px' }} /> {/* Reduced the size to 20px */}
      </button>
      <button
        onClick={() => setViewMode('grid')}
        style={viewMode === 'grid' ? selectedButtonStyle : buttonStyle}
      >
        <GridIcon />
      </button>
      <button
        onClick={() => setViewMode('list')}
        style={viewMode === 'list' ? selectedButtonStyle : buttonStyle}
      >
        <FontAwesomeIcon icon={faList} style={{ fontSize: '20px' }} /> {/* Reduced the size to 20px */}
      </button>
    </div>
  );
};

export default ViewModeSelector;
