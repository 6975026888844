import { Link } from "react-router-dom";
import Facebook from "../../images/social_icons/facebook-f.svg";
import Instagram from "../../images/social_icons/instagram.svg";
import Twitter from "../../images/social_icons/twitter.svg";
import Linkedin from "../../images/social_icons/linkedin.svg";
import Youtube from "../../images/social_icons/youtube.svg";
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import GenInTouch from "../GenInTouch";

const Footer = () => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const isWhiteOrShade = (color: string) => {
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      return r >= 240 && g >= 240 && b >= 240;
    }
    return false;
  };
  
  return (
    <>
      {getThemes && getThemes.website === "https://trustbricks.co.uk" && (
        <footer
          className="w-full py-10 px-4 sm:px-6 lg:px-8 mx-auto"
          style={{ background: getThemes && getThemes.color1 }}
        >
          {/* <!-- Grid --> */}
          <div className="max-w-7xl m-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
            <div className="col-span-full hidden lg:col-span-1 lg:block text-left">
              <Link
                className="flex-none text-xl font-semibold dark:text-white"
                to="/"
                aria-label="Trustbricks"
              >
                <img
                  src={getThemes && getThemes.logo}
                  className="w-48"
                  alt="Trustbricks logo"
                />
              </Link>
              <p className="mt-3 text-xs sm:text-sm text-white-f2f9fa">
                Unit 6, Church Farm, Church Road, Suffolk<br></br>
                United Kingdom, IP29 5AX
              </p>
            </div>
            {/* <!-- End Col --> */}

            {/* ... (rest of the columns remain unchanged) ... */}

            <div className="text-left text-white-f2f9fa">
              <h4 className="text-lg font-medium text-white-f2f9fa uppercase">
                Stay Connected
              </h4>

              <div className="my-5 gap-5 flex text-sm">
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.facebook.com/trustbricks"
                >
                  <img src={Facebook} className="w-6 h-6" alt="Facebook icon" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.instagram.com/trust_bricks/"
                >
                  <img src={Instagram} className="w-6 h-6" alt="Instagram icon" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://twitter.com/trustbricks/"
                >
                  <img src={Twitter} className="w-6 h-6" alt="Twitter icon" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.linkedin.com/company/trustbricks/"
                >
                  <img src={Linkedin} className="w-6 h-6" alt="LinkedIn icon" />
                </Link>
                <Link
                  className="flex items-center sm:py-2"
                  to="https://www.youtube.com/channel/UCtbHPeoFa4tQaWSJLrOsrcQ/"
                >
                  <img src={Youtube} className="w-6 h-6" alt="YouTube icon" />
                </Link>
              </div>
              <img
                src="https://trustbricks.co.uk/wp-content/uploads/2022/08/PRS_Logo_v2.png"
                alt="PRS Logo"
                className="w-2/3 py-2"
              />
            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- End Grid --> */}

          <div className="max-w-7xl m-auto pt-5 mt-5">
            <div className="flex flex-row justify-evenly w-full">
              <div className="space-x-4 text-sm lg:w-1/2 sm:w-full flex">
                <p className="text-white-f2f9fa text-left w-full">
                  Copyright © 2023
                </p>
              </div>
              <div className="space-x-4 text-sm ml-4 lg:w-1/2 sm:w-full flex">
                <p className="text-white-f2f9fa w-full text-right">
                  <Link
                    className=""
                    to="https://trustbricks.co.uk/privacy-policy/"
                  >
                    Privacy policy
                  </Link>
                  <span className="px-1">|</span>
                  <Link
                    className=""
                    to="https://trustbricks.co.uk/legal-notice/"
                  >
                    Legal notice
                  </Link>
                </p>
              </div>

              {/* <!-- End Col --> */}
            </div>
          </div>
        </footer>
       )}
       {getThemes && getThemes.website !== "https://trustbricks.co.uk" && (
  <footer
    className="w-full py-5 px-4 sm:px-6 lg:px-8 mx-auto justify-center flex"
    style={{ background: getThemes.color1 }}
  >
    <div>
      <p
        className="text-left w-full"
        style={{ color: isWhiteOrShade(getThemes.color1) ? "black" : getThemes.txtColor }}
      >
        Copyright © 2024
      </p>
    </div>
  </footer>
)}
<GenInTouch />
</>
);

 };
 
 export default Footer;
