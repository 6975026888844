
function Blog() {
  return (
    <div
      className="Home pt-28 flex justify-center items-center h-[93.2vh]"
    >
      <h3 className="lg:text-3xl text-2xl font-semibold text-center leading-tight pb-6 justify-center items-center align-middle">
          We’re currently updating our blog – please bear with us.
          </h3>
    </div>
  );
}

export default Blog;
