import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import html3pdf from 'html3pdf';
import { QRCodeCanvas } from 'qrcode.react';
import useThemeStore from "../../store/themeStore";
import { ThemeProps } from "../../utils/theme";
import { lighten } from 'polished';
import useIsMobile from '../../hooks/useIsMobile';

// Utility functions
const formatNumber = (value: number): string => {
  return value ? value.toLocaleString() : 'N/A';
};

const isWhiteOrShade = (color: string): boolean => {
  if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
  if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;
  const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
  if (rgba) {
    const [, r, g, b] = rgba.map(Number);
    return r >= 240 && g >= 240 && b >= 240;
  }
  return false;
};

const getBackgroundColor = (theme: any): string => {
  if (isWhiteOrShade(theme.color1)) {
    return lighten(0.6, theme.color2);
  } else {
    return lighten(0.6, theme.color1);
  }
};


// PropertyPDFGenerator component
const PropertyPDFGenerator = ({ property, theme }: { property: any, theme: any }) => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );
  
  if (!property?.results) {
    console.error('No property data available');
    return <div>No property data available</div>;
  }

  const availableImages = (property.results.images || []).filter((img: any) => img && img.url);
  const imageSlots = Array(6).fill(null).map((_, index) => availableImages[index] || null);
  const propertyId = property.results._id || property.results.id;
  const websiteUrl = theme.website || window.location.origin;
  const propertyUrl = `${websiteUrl}/listing/${propertyId}`;
  const hideQRCode = !getThemes?.hideQR;

  const infoItems = [
    { label: "Type", value: property?.results.houseSubtype || 'N/A' },
    { label: "Bedrooms", value: property?.results.bedrooms ?? 'N/A' },
    { label: "Tenure", value: property?.results.tenureType || 'N/A' },
    { label: "Strategy", value: property?.results.strategy || 'N/A' },
    { label: "Price", value: property?.results.price ? `£${formatNumber(property?.results.price)}` : 'N/A' },
    { label: "Zoopla Valuation", value: property?.results.estimatedValue ? `£${formatNumber(property?.results.estimatedValue)}` : 'N/A' },
    { label: "Occupancy", value: property?.results.occupancy || 'N/A' },
    { label: "Gross Yield", value: property?.results.rentGrossYield ? `${property?.results.rentGrossYield}%` : 'N/A' },
    { label: "Current Rent", value: property?.results.currentRent ? `£${formatNumber(property?.results.currentRent)}` : 'N/A' },
    { label: "Estimated Rent", value: property?.results.averageRent ? `£${formatNumber(property?.results.averageRent)}` : 'N/A' },
    { label: "Size", value: property?.results.floorsqft ? `${property?.results.floorsqft} sq ft` : 'N/A' },
    { label: "EPC", value: property?.results.epc || 'N/A' },
    { label: "Flood Risk", value: property?.results.floodRisk || 'N/A' },
  ];

  const comparableTypes = [
    { title: "Previously Sold - Within The Area", data: property?.results.marketComparable },
    { title: "Previously Sold - Same Street", data: property?.results.streetComparable },
    { title: "For Sale - Within The Area", data: property?.results.saleComparable },
    { title: "For Rent - Within The Area", data: property?.results.rentComparable },
  ];

  const backgroundColor = getBackgroundColor(theme);

  // Styles for the HTML content (PDF)
  const styles: { [key: string]: React.CSSProperties } = {
    page: {
      padding: '20px',
      margin: 0,
      fontSize: 12,
      fontFamily: 'Helvetica',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 12,
      padding: '20px',
      marginBottom: 20,
      border: '1px solid #000',
    },
    logoAndTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    reportTitle: {
      color: getThemes && getThemes.txtColor ? getThemes.txtColor : 'white',
      fontSize: '16px',
      fontWeight: 'bold',
      marginTop: '10px',
      padding: '10px',
    },
    logoStyles: {
      maxWidth: '200px',
      maxHeight: '100px',
      objectFit: 'contain',
    },
    qrCodeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '20px',
      marginTop: '2px',
    },
    qrCodeWrapper: {
      padding: '10px',
      borderRadius: '10px',
      border: '2px solid white',
    },
    qrCodeText: {
      color: getThemes && getThemes.txtColor ? getThemes.txtColor : 'white',
      fontSize: '10px',
      marginTop: '5px',
    },
    sectionTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
      color: '#333',
      textDecoration: 'underline',
      paddingLeft: '10px',
    },
    infoGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 10,
      backgroundColor: backgroundColor,
      border: '1px solid gray',
      borderRadius: 4,
      padding: '10px',
    },
    infoItem: {
      width: '50%',
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '2px',
    },
    label: {
      fontWeight: 'bold',
      marginRight: 5,
      fontSize: 15,
    },
    value: {
      flex: 1,
      fontSize: 15,
    },
    divider: {
      borderBottom: '1px solid gray',
      width: '100%',
      margin: 5,
    },
    section: {
      marginBottom: 20,
      padding: '10px',
    },
    addressPriceSection: {
      backgroundColor: isWhiteOrShade(theme.color1) ? lighten(0.6, theme.color2) : lighten(0.6, theme.color1),
      border: '1px solid gray',
      padding: '20px',
      borderRadius: '8px',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100px',
    },
    address: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#000',
      textAlign: 'center',
      marginBottom: '10px',
    },
    price: {
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#000',
      textAlign: 'center',
    },
    description: {
      marginBottom: 20,
      border: '1px solid gray',
      padding: 10,
      backgroundColor: '#f9fafb',
      borderRadius: 4,
    },
    imageSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      padding: '0',
    },
    imageContainer: {
      width: '48%',
      height: '215px',
      marginBottom: 20,
      overflow: 'hidden',
      borderRadius: '12px',
      border: '1px solid #ddd',
      padding: '10px',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '12px',
    },
    placeholderImage: {
      width: '100%',
      height: '100%',
      backgroundColor: '#f0f0f0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px',
    },
    placeholderText: {
      color: '#999',
    },
    disclaimer: {
      fontSize: 8,
      color: '#666',
      lineHeight: '1.5',
      marginBottom: '5px',
      textAlign: 'center',
    },
    comparablesSection: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: 20,
      padding: '10px',
    },
    comparableCard: {
      width: '48%',
      border: '1px solid gray',
      borderRadius: 4,
      padding: '10px',
      marginBottom: 10,
      backgroundColor: isWhiteOrShade(theme.color1) ? lighten(0.6, theme.color2) : lighten(0.6, theme.color1),
    },
    comparableTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 5,
      textAlign: 'center',
      textDecoration: 'underline',
    },
    comparableItem: {
      marginBottom: 3,
      textAlign: 'center',
    },
    comparableAddress: {
      fontSize: 11,
    },
    comparablePrice: {
      fontSize: 11,
      fontWeight: 'bold',
      paddingLeft: '5px',
    },
    noData: {
      fontSize: 9,
      fontStyle: 'italic',
      color: '#666',
    },
  };

    const qrCodeStyle = isWhiteOrShade(theme.color1)
    ? {
        backgroundColor: '#000000', // Black background when theme.color1 is white/shade
        padding: '10px',
        borderRadius: '10px',
      }
    : {
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '10px',
      };

  const qrCodeFgColor = isWhiteOrShade(theme.color1) ? '#ffffff' : '#000000';
  const qrCodeBgColor = isWhiteOrShade(theme.color1) ? '#000000' : '#ffffff';

  const getAddressPriceSectionStyle = () => ({
    ...styles.addressPriceSection,
    backgroundColor: lighten(
      0.6,
      isWhiteOrShade(theme.color1) ? theme.color2 : theme.color1
    ),
  });

  return (
    <div style={styles.page}>
      <div
        style={{
          ...styles.header,
          backgroundColor: theme.color1,
        }}
      >
        <div style={styles.logoAndTitleContainer}>
          <img src={theme.logo} alt="Logo" style={styles.logoStyles} />
          <div style={styles.reportTitle}>Property Information Report</div>
        </div>
        {!hideQRCode && (
          <div style={styles.qrCodeContainer}>
            <div style={{ ...styles.qrCodeWrapper, ...qrCodeStyle }}>
              <QRCodeCanvas 
                value={propertyUrl} 
                size={80} 
                fgColor={qrCodeFgColor}
                bgColor={qrCodeBgColor}
              />
            </div>
            <p style={styles.qrCodeText}>Scan to view property</p>
          </div>
        )}
      </div>

      <div style={getAddressPriceSectionStyle()}>
        <h2 style={styles.address}>Address: {property.results.fullAddress || 'No address available'}</h2>
        <p style={styles.price}>
          Asking Price: £
          {property.results.price ? formatNumber(property.results.price) : 'N/A'}
        </p>
      </div>

      <div style={styles.imageSection}>
        {imageSlots.map((img, index) => (
          <div key={index} style={styles.imageContainer}>
            {img ? (
              <img 
                src={img.url} 
                alt={`Property ${index + 1}`} 
                style={styles.image} 
              />
            ) : (
              <div style={styles.placeholderImage}>
                <span style={styles.placeholderText}>No Image</span>
              </div>
            )}
          </div>
        ))}
      </div>

      <div style={{ ...styles.section, pageBreakBefore: 'always' }}>
        <div
          style={{
            ...styles.header,
            backgroundColor: theme.color1,
          }}
        >
          <div style={styles.logoAndTitleContainer}>
            <img src={theme.logo} alt="Logo" style={styles.logoStyles} />
            <div style={styles.reportTitle}>Property Information Report</div>
          </div>
          {!hideQRCode && (
            <div style={styles.qrCodeContainer}>
              <div style={{ ...styles.qrCodeWrapper, ...qrCodeStyle }}>
                <QRCodeCanvas 
                  value={propertyUrl} 
                  size={80} 
                  fgColor={qrCodeFgColor}
                  bgColor={qrCodeBgColor}
                />
              </div>
              <p style={styles.qrCodeText}>Scan to view property</p>
            </div>
          )}
        </div>

        <h3 style={styles.sectionTitle}>Property Information:</h3>
        <div style={styles.infoGrid}>
          {infoItems.map((item, index) => (
            <React.Fragment key={index}>
              <div style={styles.infoItem}>
                <strong style={styles.label}>{item.label}:</strong>
                <span style={styles.value}>{item.value}</span>
              </div>
              {index % 2 === 1 && index !== infoItems.length - 1 && (
                <div style={styles.divider}></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <h3 style={styles.sectionTitle}>Comparable Data:</h3>
      <div style={styles.comparablesSection}>
        {comparableTypes.map((type, index) => (
          <div key={index} style={styles.comparableCard}>
            <h4 style={styles.comparableTitle}>{type.title}</h4>
            {type.data && type.data.length > 0 ? (
              type.data.slice(0, 3).map((comp: string, compIndex: number) => {
                const [address, price] = comp.split('-').map((item) => item.trim());
                return (
                  <div key={compIndex} style={styles.comparableItem}>
                    <span style={styles.comparableAddress}>{address}</span>
                    <span style={styles.comparablePrice}>- {price}</span>
                  </div>
                );
              })
            ) : (
              <p style={styles.noData}>No data available</p>
            )}
          </div>
        ))}
      </div>

      {/* Disclaimer */}
      <div style={styles.section}>
        <p style={styles.disclaimer}>
          <strong>Disclaimer:</strong>
        </p>
        <p style={styles.disclaimer}>
          This is for your information only – you should not view this as legal advice, tax advice,
          investment advice, or any advice at all. This information does not constitute a
          solicitation, recommendation, endorsement in this or in any other jurisdiction.
  
          While we have tried to make sure this information is accurate and up to date, things can
          change, so it should not be viewed as totally comprehensive. We always recommend you seek
          out independent advice before making any investment decisions.
        </p>
        <p style={styles.disclaimer}>
          With respect to Gross Yield & Rental Income, these values are either provided by our
          partners or derived by comparing similar properties within the same geographical location.
          Gross yield is calculated using the standard industry formula by taking the annual rental
          income of the property and dividing it by the purchase price of the property.

          Any information pertaining to the EPC has been retrieved via the UK government public
          database of EPC certificates. Stock listed may on occasion be sold or withdrawn by Vendors
          at short notice, and thus be no longer available.
        </p>
      </div>
    </div>
  );
};

// PropertyPDFDownloadLink component
const PropertyPDFDownloadLink = ({ property, theme }: { property: any, theme: any }) => {
  const [status, setStatus] = useState<'idle' | 'generating' | 'ready'>('idle');
  const [showModal, setShowModal] = useState(false);
  const pdfRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const generatePDF = useCallback(() => {
    setStatus('generating');
    
    setTimeout(() => {
      const element = pdfRef.current;

      if (!element) {
        console.error('PDF element not found');
        setStatus('ready');
        return;
      }

      const opt = {
        margin: [35, 4, 4, 4],
        filename: `${property?.results?.fullAddress || 'property'}-report.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          allowTaint: true,
          logging: true,
        },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      };

      const images = element.getElementsByTagName('img');
      const imagePromises = Array.from(images).map(
        (img) =>
          new Promise<void>((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = () => {
                resolve();
              };
              img.onerror = () => {
                console.error(`Error loading image: ${img.src}`);
                resolve();
              };
            }
          })
      );

      Promise.all(imagePromises)
        .then(() => {
          html3pdf()
            .from(element)
            .set(opt)
            .save()
            .then(() => {
              setStatus('ready');
              setShowModal(false);
            })
            .catch((error: any) => {
              console.error('Error during PDF generation:', error);
              setStatus('ready');
            });
        })
        .catch((error) => {
          console.error('Error loading images:', error);
          setStatus('ready');
        });
    }, 100);
  }, [property]);

  const handleClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      const element = pdfRef.current;
      if (element) {
      } else {
        console.error('PDF element still not found after modal render');
      }
    }
  }, [showModal]);

  const modalStyles: { [key: string]: React.CSSProperties } = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
      padding: isMobile ? '10px' : '0',
    },
    modal: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '20px',
      width: isMobile ? '100%' : '90%',
      maxWidth: isMobile ? '100%' : '900px',
      minWidth: isMobile ? '100%' : '600px',
      maxHeight: '90vh',
      overflow: 'auto',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 10000,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
      flexDirection: isMobile ? 'column' : 'row',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '4px',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      width: isMobile ? '100%' : 'auto',
      marginBottom: isMobile ? '10px' : '0',
      marginRight: isMobile ? '0' : '10px',
    },
    closeButton: {
      backgroundColor: '#e5e7eb',
      color: '#4b5563',
      width: isMobile ? '100%' : 'auto',
    },
  };
  

  // Styles for mobile preview
  const mobilePreviewStyles: { [key: string]: React.CSSProperties } = {
    container: {
      fontFamily: 'Arial, sans-serif',
      padding: '10px',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: theme.color1,
      borderRadius: '4px',
    },
    logoAndTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    logo: {
      maxWidth: '100px',
      maxHeight: '50px',
      objectFit: 'contain',
    },
    reportTitle: {
      color: theme.txtColor || 'white',
      fontSize: '14px',
      fontWeight: 'bold',
      marginTop: '5px',
    },
    qrCodeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    qrCodeWrapper: {
      backgroundColor: 'white',
      padding: '5px',
      borderRadius: '5px',
    },
    qrCodeText: {
      color: 'white',
      fontSize: '8px',
      marginTop: '2px',
    },
    addressPriceSection: {
      padding: '10px',
      borderRadius: '4px',
      marginTop: '10px',
    },
    address: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: theme.txtColor || 'black',
      marginBottom: '5px',
    },
    price: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.txtColor || 'black',
    },
    previewText: {
      fontSize: '14px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '20px',
    },
  };

  const overlayStyles: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10001,
  };

  const MobilePreviewContent = () => {
    const qrCodeStyle = isWhiteOrShade(theme.color1)
    ? {
        backgroundColor: '#000000', // Black background when theme.color1 is white/shade
        padding: '10px',
        borderRadius: '10px',
      }
    : {
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '10px',
      };

  const qrCodeFgColor = isWhiteOrShade(theme.color1) ? '#ffffff' : '#000000';
  const qrCodeBgColor = isWhiteOrShade(theme.color1) ? '#000000' : '#ffffff';

    return (
      <div style={mobilePreviewStyles.container}>
        <div style={mobilePreviewStyles.header}>
          <div style={mobilePreviewStyles.logoAndTitleContainer}>
            <img src={theme.logo} alt="Logo" style={mobilePreviewStyles.logo} />
            <div style={mobilePreviewStyles.reportTitle}>Property Information Report</div>
          </div>
          <div style={mobilePreviewStyles.qrCodeContainer}>
            <div style={{ ...mobilePreviewStyles.qrCodeWrapper, ...qrCodeStyle }}>
              <QRCodeCanvas 
                value={`${window.location.origin}/listing/${property.results._id}`} 
                size={40} 
                fgColor={qrCodeFgColor}
                bgColor={qrCodeBgColor}
              />
            </div>
            <p style={mobilePreviewStyles.qrCodeText}>Scan to view</p>
          </div>
        </div>
        <div style={mobilePreviewStyles.addressPriceSection}>
          <h2 style={mobilePreviewStyles.address}>{property.results.fullAddress || 'No address available'}</h2>
          <p style={mobilePreviewStyles.price}>
            Asking Price: £
            {property.results.price ? property.results.price.toLocaleString() : 'N/A'}
          </p>
        </div>
        <p style={mobilePreviewStyles.previewText}>
          Tap 'Download PDF' to generate and view the full report.
        </p>
      </div>
    );
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="text-blue-500 hover:text-blue-700 focus:outline-none"
        aria-label="Download PDF"
      >
        <FontAwesomeIcon icon={faFilePdf} size="lg" />
      </button>

      {showModal &&
        ReactDOM.createPortal(
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              {isMobile && <MobilePreviewContent />}
              <div 
                style={{ 
                  position: 'relative', 
                  height: isMobile ? 0 : '60vh', 
                  overflow: isMobile ? 'hidden' : 'auto',
                  visibility: isMobile ? 'hidden' : 'visible'
                }}
              >
                <div ref={pdfRef}>
                  <PropertyPDFGenerator property={property} theme={theme} />
                </div>
              </div>
              {status === 'generating' && (
                <div style={overlayStyles}>
                  <div style={{ textAlign: 'center' }}>
                    <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>Generating PDF</h2>
                    <p>Please wait while we create your report...</p>
                  </div>
                </div>
              )}
              <div style={modalStyles.buttonContainer}>
              <button
                onClick={generatePDF}
                style={{
                  ...modalStyles.button,
                  backgroundColor: theme.color2 || '#3b82f6',
                  color: isWhiteOrShade(theme.color1) ? 'white' : theme.txtColor,
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = isWhiteOrShade(theme.color1) ? 'black' : theme.color1 || '#2563eb';
                  e.currentTarget.style.color = isWhiteOrShade(theme.color1) ? 'white' : theme.txtColor;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = theme.color2 || '#3b82f6';
                  e.currentTarget.style.color = isWhiteOrShade(theme.color1) ? 'white' : theme.txtColor;
                }}
                disabled={status === 'generating'}
              >
                {status === 'generating' ? 'Generating...' : 'Download PDF'}
              </button>
                <button
                  onClick={() => setShowModal(false)}
                  style={{ ...modalStyles.button, ...modalStyles.closeButton }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = isWhiteOrShade(theme?.color1) ? "black" : theme?.color2;
                    e.currentTarget.style.color = isWhiteOrShade(theme?.color1) ? "white" : theme?.txtColor;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#e5e7eb';
                    e.currentTarget.style.color = '#4b5563';
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            </div>,
          document.body
        )}
    </>
  );
};

export default PropertyPDFDownloadLink;