import React, { useState, useEffect, useRef } from 'react';
import PropertyCard from "../../components/property/PropertyCard";
import useThemeStore from '../../store/themeStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCopy, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Property, getProperties, PropertiesSearch } from '../../utils/properties';
import ReactDOM from 'react-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Theme {
  color1: string;
  color2: string;
  txtColor: string;
}

interface AdvertisingCarouselProps {
  region: string;
  limit?: number;
}

const AdvertisingCarousel: React.FC<AdvertisingCarouselProps> = ({ region, limit = 10 }) => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(4);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const [animateIcons, setAnimateIcons] = useState(false);
  const [copyFeedback, setCopyFeedback] = useState('Copy Link');
  const carouselRef = useRef<HTMLDivElement>(null);
  const getThemes = useThemeStore((state) => state.theme as unknown as Theme);

  useEffect(() => {
    const fetchProperties = async () => {
      let collectedProperties: Property[] = [];
      let currentPage = 1;
      let hasMore = true;

      while (hasMore && collectedProperties.length < limit) {
        const filter: PropertiesSearch = { 
          region, 
          limit: 200,
          page: currentPage, 
          sortDate: "Latest"
        };
        try {
          const response = await getProperties(filter);
          const filteredProperties = response.results.filter(
            (property) => !property.reserved
          );

          collectedProperties = [...collectedProperties, ...filteredProperties];

          if (filteredProperties.length < 10) {
            hasMore = false;
          } else {
            currentPage++;
          }
        } catch (error) {
          console.error("Failed to fetch properties:", error);
          hasMore = false;
        }
      }

      if (collectedProperties.length < limit) {
        const repeatsNeeded = Math.ceil(limit / collectedProperties.length);
        const repeatedProperties = Array(repeatsNeeded).fill(collectedProperties).flat().slice(0, limit);
        setProperties(repeatedProperties);
      } else {
        setProperties(collectedProperties.slice(0, limit));
      }
    };

    fetchProperties();

    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setVisibleCards(1);
      } else if (width >= 640 && width < 768) {
        setVisibleCards(2);
      } else if (width >= 768 && width < 1200) {
        // This range covers most iPad models in both portrait and landscape
        setVisibleCards(2);
      } else {
        setVisibleCards(4);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [region, limit]);

  useEffect(() => {
    if (isShareModalOpen) {
      setAnimateIcons(true);
      setTimeout(() => setAnimateIcons(false), 800);
    }
  }, [isShareModalOpen]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + visibleCards) % properties.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - visibleCards + properties.length) % properties.length);
  };

  const getVisibleProperties = () => {
    const visibleProps = [];
    const totalProperties = properties.length;

    for (let i = 0; i < Math.min(visibleCards, totalProperties); i++) {
      const index = (currentIndex + i) % totalProperties;
      visibleProps.push(properties[index]);
    }

    return visibleProps;
  };

  const openShareModal = (property: Property) => {
    setSelectedProperty(property);
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
    setSelectedProperty(null);
  };

  const generatePropertyLink = (propertyId: string) => {
    const baseUrl = window.location.origin; 
    return `${baseUrl}/listing/${propertyId}`;
  };

  const handleCopyLink = () => {
    if (selectedProperty) {
      const link = generatePropertyLink(selectedProperty._id);
      navigator.clipboard.writeText(link);
      setCopyFeedback('Link Copied');
      setTimeout(() => {
        setCopyFeedback('Copy Link');
        closeShareModal();
      }, 2000);
    }
  };

  const handleWhatsAppShare = () => {
    if (selectedProperty) {
      const link = generatePropertyLink(selectedProperty._id);
      window.open(`https://wa.me/?text=${encodeURIComponent(link)}`, '_blank');
      closeShareModal();
    }
  };

  const handleEmailShare = () => {
    if (selectedProperty) {
      const link = generatePropertyLink(selectedProperty._id);
      window.location.href = `mailto:?subject=Check out this property&body=${encodeURIComponent(link)}`;
      closeShareModal();
    }
  };

  if (properties.length === 0) {
    return null;
  }

  return (
    <div className="container mx-auto p-4 my-8">
      <h2 className="text-2xl font-bold mb-4 text-center">Other Properties available in {region}</h2>
      <div className="relative">
        <div className="overflow-hidden">
          <div className="flex -mx-2" ref={carouselRef}>
            {getVisibleProperties().map((property, index) => {
              const shouldShowStrategy = !property.discountToMarket;

              return (
                <div
                  key={`${property._id}-${index}`}
                  className={`w-full px-2 mb-4 flex-shrink-0`}
                  style={{ width: `${100 / visibleCards}%` }}
                >
                  <PropertyCard
                    discount={property.discountToMarket as unknown as string}
                    discountLabel={property.discountLabel}
                    id={property._id}
                    available={!property.reserved}
                    image={property.images?.[0]?.url || "https://betaapi.truevals.com/uploads/properties/Main_Image.jpeg"}
                    streetName={property.propertyOf}
                    price={property.price as unknown as string}
                    bedrooms={property.bedrooms as unknown as string}
                    value={property.estimatedValue as unknown as string}
                    rent={property.averageRent as unknown as string}
                    netCashFlow={property.netCashflow as unknown as string}
                    grossYield={property.rentGrossYield as unknown as string}
                    strategy={shouldShowStrategy ? (property.strategy as unknown as string) : ""}
                    occupancy={property.occupancy}
                    tenureType={property.tenureType}
                    floorsqft={property.floorsqft}
                    region={property.region}
                    investment={property.investment as unknown as string}
                    onShareClick={() => openShareModal(property)}
                    onClick={() => {/* Handle card click */}}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <button 
          onClick={prevSlide} 
          className="absolute bottom-60 -left-4 transform text-black p-2 rounded-md shadow-md transition-all duration-300 ease-in-out"
          style={{
            backgroundColor: 'white',
            height: '58px',
            width: '30px',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = getThemes?.color2 || 'gray';
            e.currentTarget.style.color = 'white';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = 'black';
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button 
          onClick={nextSlide} 
          className="absolute bottom-60 -right-4 transform text-black p-2 rounded-md shadow-md transition-all duration-300 ease-in-out"
          style={{
            backgroundColor: 'white',
            height: '58px',
            width: '30px',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = getThemes?.color2 || 'gray';
            e.currentTarget.style.color = 'white';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = 'black';
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      {isShareModalOpen && selectedProperty && ReactDOM.createPortal(
        <div className="fixed inset-0 flex items-center justify-center z-[9999]" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="bg-white rounded-lg p-6 w-96 relative" style={{ backgroundColor: 'white' }}>
            <style>
              {`
                @keyframes pulse {
                  0%, 100% { transform: scale(1); }
                  30% { transform: scale(1.2); }
                }
                .pulse {
                  animation: pulse 1s infinite;
                }
              `}
            </style>
            <span 
              onClick={closeShareModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "bold",
                transition: "transform 0.2s",
                display: "inline-block",
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.3)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              &times;
            </span>
            <h3 className="text-2xl font-bold mb-4 text-center">Share Property</h3>
            <div className="flex items-center mb-4">
              {selectedProperty.images && selectedProperty.images.length > 0 && (
                <LazyLoadImage
                  src={selectedProperty.images[0].url}
                  width={120}
                  height={60}
                  className="rounded mr-4 object-cover"
                  alt="Property Thumbnail"
                  style={{ width: '140px', height: '80px', objectFit: 'cover' }}
                />
              )}
              <div>
                <h4 className="font-medium">{selectedProperty.propertyOf}</h4>
                <p className="text-gray-600">{selectedProperty.fullAddress}</p>
              </div>
            </div>
            <p className="mb-4 text-center">
              You can share the following link for anyone to view this property
            </p>
            <div className="flex justify-around">
              <button
                className={`flex flex-col items-center focus:outline-none transition duration-300 ease-in-out transform hover:scale-110 ${animateIcons ? 'pulse' : ''}`}
                onClick={handleCopyLink}
              >
                <FontAwesomeIcon icon={faCopy} size="1x" className="mb-2" style={{ fontSize: '1.5rem' }} />
                <span style={{ fontSize: '0.7rem' }}>{copyFeedback}</span>
              </button>
              <div className={`flex flex-col items-center ${animateIcons ? 'pulse' : ''}`}>
                <button
                  className="flex items-center justify-center focus:outline-none transition duration-300 ease-in-out transform hover:scale-110"
                  onClick={handleWhatsAppShare}
                  style={{ width: '2rem', height: '2rem', backgroundColor: '#25D366', borderRadius: '50%', justifyContent: 'center', alignItems: 'center' }}
                >
                  <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '1.3rem', color: 'white' }} />
                </button>
                <span style={{ fontSize: '0.7rem' }}>Whatsapp</span>
              </div>
              <button
                className={`flex flex-col items-center focus:outline-none transition duration-300 ease-in-out transform hover:scale-110 ${animateIcons ? 'pulse' : ''}`}
                onClick={handleEmailShare}
              >
                <FontAwesomeIcon icon={faEnvelope} size="1x" className="mb-2" style={{ fontSize: '1.5rem' }} />
                <span style={{ fontSize: '0.7rem' }}>Email</span>
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default AdvertisingCarousel;