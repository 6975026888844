import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import FeatureEnquiryModal from "./components/FeatureEnquiryModal";
import PixelatedButton from './components/PixelatedButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCity } from '@fortawesome/free-solid-svg-icons';
import useCardList from '../data/useCardList';

interface PropertyNavigationProps {
  currentPage: string;
}

interface NavItemProps {
  icon: any;
  text: string;
  isActive: boolean;
  linkTo: string;
}

const NavItem: React.FC<NavItemProps> = ({ icon, text, isActive, linkTo }) => (
  <NavLink 
    to={linkTo} 
    className={`flex flex-col items-center text-xs sm:text-sm md:text-base lg:text-sm xl:text-xs px-4 py-2 rounded-lg transition-colors duration-200 ease-in-out ${
      isActive ? 'text-white bg-blue-600' : 'text-gray-700 hover:bg-gray-200'
    }`}
  >
    <FontAwesomeIcon 
      icon={icon} 
      className="text-lg sm:text-xl md:text-2xl lg:text-xl xl:text-lg mb-1" 
    />
    <span>{text}</span>
  </NavLink>
);

const PropertyNavigation: React.FC<PropertyNavigationProps> = ({ currentPage }) => {
  return (
    <div className="max-w-7xl mx-auto my-8 px-4">
      <nav className="flex justify-between sm:justify-start items-center gap-2 sm:gap-4 flex-wrap">
        <NavItem 
          icon={faMagnifyingGlass} 
          text="Find Property" 
          isActive={currentPage === 'find'}
          linkTo="/listing"
        />
        <NavItem 
          icon={faCity} 
          text="Explore Services" 
          isActive={currentPage === 'services'}
          linkTo="/Services"
        />
      </nav>
    </div>
  );
};

const ServicesPage: React.FC = () => {
  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const cardList = useCardList();

  const handleOpenModal = (productId: string) => {
    setSelectedProductId(productId);
    setIsModalOpen(true);
  };

  const triggerSpeakToTeam = () => {
    window.dispatchEvent(new Event('speak-to-team'));
  };

  const [, setIsAlternateColor] = useState(false);

  useEffect(() => {
    const colorInterval = setInterval(() => {
      setIsAlternateColor(prev => !prev);
    }, 4000);

    return () => clearInterval(colorInterval);
  }, []);

  return (
    <div className="ServicesPage bg-primarycolor pt-28" style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>Our Services</title>
        <meta name="description" content="Explore our comprehensive range of property-related services" />
      </Helmet>

      <PropertyNavigation currentPage="services" />

      <div className="relative w-full" style={{ 
        backgroundImage: `url(${backgroundPattern})`, 
        backgroundRepeat: 'repeat', 
        backgroundSize: '100px 100px',
        padding: '4rem 0'
      }}>
       <div className="max-w-7xl mx-auto px-4 p-4 sm:px-6 lg:px-8 mb-12">
        <div className="text-center border rounded-xl shadow-md p-4" style={{ backgroundColor: 'white' }}>
          <h1 className="text-4xl font-bold text-black sm:text-5xl md:text-6xl relative inline-block">
            Our Services
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-black sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Comprehensive solutions for all your property needs
          </p>
        </div>
      </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="inline-block bg-white rounded-xl shadow-md mb-8 p-4" style={{ backgroundColor: "white" }}>
            <h2 className="text-3xl font-bold text-center relative inline-block pb-2">
              Featured Products
              <div style={{ position: 'absolute', bottom: 2, left: 0, width: '100%', height: '2px', backgroundColor: 'black' }}></div>
            </h2>
          </div>
          <div className="flex flex-wrap justify-center gap-6">
            {cardList.map((card, index) => (
              <div key={index} className="w-full sm:w-auto flex justify-center" style={{ maxWidth: '384px' }}>
                <div className="w-full border rounded-xl relative shadow-md hover:shadow-xl transition duration-150 ease-in-out" style={{ backgroundColor: 'white' }}>
                  <div className="w-full h-60 bg-white rounded-t-xl overflow-hidden">
                    <img
                      src={card.logo}
                      alt={card.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-4">{card.title}</h3>
                    <ul className="list-disc list-inside text-sm text-gray-600 mb-6">
                      {card.bulletPoints.map((point, idx) => (
                        <li key={idx}>{point}</li>
                      ))}
                    </ul>
                    <button
                      onClick={() => handleOpenModal(card.productId)}
                      style={{
                        background: getThemes.color1,
                        color: getThemes.txtColor,
                        padding: "10px",
                        width: "100%",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      onMouseOver={(e) => e.currentTarget.style.background = getThemes.color2}
                      onMouseOut={(e) => e.currentTarget.style.background = getThemes.color1}
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="text-center lg:text-left mb-6 lg:mb-0">
              <p className="text-3xl font-bold text-gray-900">Ready to get started?</p>
              <p className="text-3xl font-bold text-gray-900">Need more information?</p>
            </div>
            <div className="flex flex-col lg:flex-row items-center">
              <p className="text-3xl font-bold text-gray-900 mb-4 lg:mb-0 lg:mr-8">Contact us today:  </p>
              <PixelatedButton
                color1={getThemes.color1}
                color2={getThemes.color2}
                textColor={getThemes.txtColor}
                onClick={triggerSpeakToTeam}
              />
            </div>
          </div>
        </div>
      </div>

      <FeatureEnquiryModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        productId={selectedProductId}
      />
    </div>
  );
};

export default ServicesPage;