import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrain, faSchool, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { PropertyExtraResult, NearestStationEntity, NearestSchoolEntity } from '../../utils/properties';

interface ThemeProps {
  color2: string;
  color1: string;
  txtColor: string;
}

interface LocalAreaInfoSectionProps {
  property: PropertyExtraResult | undefined;
  getThemes: ThemeProps;
}

const LocalAreaInfoSection: React.FC<LocalAreaInfoSectionProps> = ({ property, getThemes }) => {
  if (!property || !property.results) {
    return null; // or some loading state
  }

  return (
    <div className="container mx-auto p-4" style={{backgroundColor: "white"}}>
      <div className="w-full bg-white border-gray-300 border shadow-sm rounded-xl font-roboto">
        <div className="border-b border-gray-300 rounded-t-xl py-3 px-4 md:py-4 md:px-5">
          <h3 className="mt-1 text-2xl font-semibold tracking-normal text-primarycolor text-left">
            Additional Information – Local Area
          </h3>
        </div>
        <div className="p-3">
          <MapContainer
            center={[
              property.results.latitude ?? 54.5713726,
              property.results.longitude ?? -1.2080007,
            ]}
            zoom={20}
            scrollWheelZoom={true}
            style={{ height: 350, width: "100%" }}
          >
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/off2on/cl0hxi4ql000115o9535ccwbr/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2ZmMm9uIiwiYSI6ImNrcHM5dTdweTA4YncycG8xbzBxMHlqMWYifQ.QoQslFiXHbvPYZpXavjjew`}
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            />
            <Marker
              position={[
                property.results.latitude ?? 54.5713726,
                property.results.longitude ?? -1.2080007,
              ]}
            >
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </div>

        <div className="p-4 md:p-5 bg-white rounded-lg shadow-lg flex flex-col md:flex-row border-black border-t-2">
          <div className="w-full md:w-1/2 border-b-2 md:border-b-0 md:border-r-2 border-black flex justify-center items-center mb-4 md:mb-0">
            <NearestStations stations={property.results.nearestStation} />
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <NearestSchools schools={property.results.nearestSchool} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface StationsProps {
  stations: NearestStationEntity[] | null | undefined;
}

const NearestStations: React.FC<StationsProps> = ({ stations }) => (
  <div className="text-primarycolor font-normal text-base text-center">
    <h4 className="align-middle font-medium text-2xl gap-x-2 pb-4 items-center mb-3">
      Nearest stations
    </h4>
    <div className="font-roboto text-sm py-2 leading-7 w-full text-center">
      {stations && stations.length > 0 ? (
        stations.map((station, index) => (
          <StationOrSchoolInfo 
            key={`station-${index}`} 
            name={station.station} 
            distance={station.distance} 
            icon={faTrain} 
            isLast={index === stations.length - 1}
          />
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  </div>
);

interface SchoolsProps {
  schools: NearestSchoolEntity[] | null | undefined;
}

const NearestSchools: React.FC<SchoolsProps> = ({ schools }) => (
  <div className="text-primarycolor font-normal text-base text-center">
    <h4 className="align-middle font-medium text-2xl gap-x-2 pb-4 items-center">
      Nearest schools
    </h4>
    <div className="font-roboto text-sm py-2 leading-7 w-full text-center">
      {schools && schools.length > 0 ? (
        schools.map((school, index) => (
          <StationOrSchoolInfo 
            key={`school-${index}`} 
            name={school.school} 
            distance={school.distance} 
            icon={faSchool} 
            isLast={index === schools.length - 1}
          />
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  </div>
);

interface StationOrSchoolInfoProps {
  name: string;
  distance: string;
  icon: IconDefinition;
  isLast: boolean;
}

const StationOrSchoolInfo: React.FC<StationOrSchoolInfoProps> = ({ name, distance, icon, isLast }) => (
  <div className="align-middle flex flex-col lg:flex-row gap-x-2 mb-4 items-center justify-center">
    <div className="flex items-center gap-2">
      <FontAwesomeIcon icon={icon} />
      <b>{name} - {distance}</b>
    </div>
    {!isLast && <hr className="border-gray-300 w-full my-2 lg:hidden" />}
  </div>
);

export default LocalAreaInfoSection;
