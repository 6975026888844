import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";



const tokenStore = (set: any) => ({
  token: undefined,
  setToken: (user: any) => {
    set((state: any) => ({
      token: user.token+"B9I9N9456",
    }));
  },
  removeToken: () => {
    set(() => ({
      token: undefined,
    }));
  },
});

const useTokenStore = create(
  devtools(
    persist(tokenStore, {
      name: "token",
    })
  )
);

export default useTokenStore;
