import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import useThemeStore from '../../store/themeStore';

interface Image {
  url: string;
}

interface ImageSliderProps {
  images: Image[];
}

interface Theme {
  color1: string;
  color2: string;
  txtColor: string;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const getThemes = useThemeStore((state) => state.theme as unknown as Theme);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  const buttonStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'white',
    color: 'black',
    padding: '0.5rem',
    borderRadius: '0.375rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    transition: 'all 0.3s ease-in-out',
    border: 'none',
    cursor: 'pointer',
    height: '58px',
    width: '30px',
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.backgroundColor = getThemes?.color2 || 'gray';
    e.currentTarget.style.color = 'white';
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.backgroundColor = 'white';
    e.currentTarget.style.color = 'black';
  };

  return (
    <div className="w-full lg:flex lg:space-x-4">
      <div className="relative w-full lg:w-3/4 h-[300px] md:h-[400px] lg:h-[500px]">
        <img 
          src={images[currentIndex].url} 
          alt={`Slide ${currentIndex}`}
          className="w-full h-full object-cover"
        />
        <button 
          onClick={goToPrevious} 
          style={{...buttonStyle, left: '1rem'}}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="shadow-md transition-all duration-300 ease-in-out"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button 
          onClick={goToNext} 
          style={{...buttonStyle, right: '1rem'}}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="shadow-md transition-all duration-300 ease-in-out"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <div className="mt-4 lg:mt-0 lg:w-1/4 lg:h-[500px] h-auto overflow-y-auto lg:overflow-x-hidden overflow-x-auto flex lg:flex-col lg:space-y-2 space-x-2 lg:space-x-0">
        {images.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`flex-shrink-0 cursor-pointer ${currentIndex === slideIndex ? 'border-2 border-blue-500' : ''}`}
          >
            <img 
              src={slide.url} 
              alt={`Thumbnail ${slideIndex}`}
              className="w-24 h-24 lg:w-full lg:h-32 object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;